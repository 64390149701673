<template>
    <div class="content-grid-collection" v-if="data && contentType" :class="contentType">
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="contentType && contentType.includes('artikelen')">
                <ul>
                    <li :class="contentType.toLowerCase()" v-for="item in data" :key="item.id">
                        <BlogOverviewItem :data="item" />
                    </li>
                </ul>
            </div>

        </transition>
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="contentType && contentType.includes('faq')">
                <ul>
                    <li :class="contentType.toLowerCase()" v-for="item in data" :key="item.id">
                        <FaqItem :data="item" />
                    </li>
                </ul>
            </div>
        </transition>
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="contentType && contentType.includes('video categories')">
                <ul>
                    <li :class="'video-categories'" v-for="item in data" :key="item.id">
                        <VideoCategoryOverviewItem :data="item" />
                    </li>
                </ul>
            </div>
        </transition>
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="contentType && contentType.includes('coaches & trainers')">
                <ul>
                    <li :class="transformContentType(contentType)" v-for="item in data" :key="item.id">
                        <TeacherOverviewItem :data="item" />
                    </li>
                </ul>
            </div>
        </transition>
        <transition name="slow-fade">
            <div class="items-wrapper" v-if="contentType && contentType.includes('journeys')">
                <ul>
                    <li :class="transformContentType(contentType)" v-for="item in data" :key="item.id">
                        <JourneyOverviewItem :data="item" />
                    </li>
                </ul>
            </div>
        </transition>

        <transition name="slow-fade">
            <div class="items-wrapper component-wrapper" v-if="contentType && contentType.includes('component')">
                <PlaylistContentColumn v-for="item in data" :key="item.id" :data="item" />
            </div>
        </transition>
    </div>
</template>

<script>
// @import component
// import ClassOverviewItem from "@/components/ClassOverviewItem";

import { defineAsyncComponent } from "vue";

const BlogOverviewItem = defineAsyncComponent(() => import("@/components/BlogOverviewItem"));
const FaqItem = defineAsyncComponent(() => import("@/components/FaqItem"));
const TeacherOverviewItem = defineAsyncComponent(() => import("@/components/TeacherOverviewItem"));
const VideoCategoryOverviewItem = defineAsyncComponent(() => import("@/components/VideoCategoryOverviewItem"));
const JourneyOverviewItem = defineAsyncComponent(() => import("@/components/JourneyOverviewItem"));

const PlaylistContentColumn = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Content/PlaylistContentColumn"));

export default {
    name: "ContentGridCollection",
    components: {
        BlogOverviewItem,
        FaqItem,
        TeacherOverviewItem,
        VideoCategoryOverviewItem,
        JourneyOverviewItem,
        PlaylistContentColumn
    },
    inject: ["isAuthenticated"],
    data() {
        return {};
    },
    props: ["data", "contentType", 'overviewItemType'],
    created() {
        //console.log(this.contentType);
    },
    methods: {
        transformContentType(valType) {

            let valString = valType;

            let replaceChars = valString.replaceAll(/[^A-Z0-9]/ig, "");
            let returnString = replaceChars.toLowerCase();

            return returnString;

        }
    }
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
